import styled from 'styled-components';

const ShapeWrapper = styled.div`
    position: absolute;
    ${({ position }) =>
        position === 'left'
            ? 'left: 0; transform: rotate(180deg);'
            : 'right: 0;'}
    top: 100px;
    max-width: 750px;
    z-index: -99;
`;

export default ShapeWrapper;
