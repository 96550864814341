import React from 'react';
import Image from '../../common/components/Image';
import BannerObject1 from '../../common/assets/image/banner/bannerObject1.png';
import ShapeWrapper from './BackgroundShape.style';

const BackgroundShape = ({ position, className }) => {
    return (
        <ShapeWrapper position={position} className={className}>
            <Image src={BannerObject1} alt="BannerObject1" />
        </ShapeWrapper>
    );
};

export default BackgroundShape;
